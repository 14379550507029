import React from "react"
import { Jumbotron } from "reactstrap"
import { 
  Container,
  Row,
  Col,
} from "reactstrap"
import MainNavBar from "../components/mainnavbar" 
import MainJumbotron from "../components/jumbotron" 
import Footer from "../components/mainfooter"
import "bootstrap/dist/css/bootstrap.min.css";

// O CSS da imagem está com 100% de largura e altura, dar atenção a isso.
export default () => (
  <div>
  <MainNavBar />
  <Jumbotron fluid className="hero hero_heidelberg"></Jumbotron>
    <Container>
        <Row className="mb-3">
            <Col id="confissao" className="heidelberg">
            <h1 className="display-3 text-dark text-center text_font_tinos font_variant_smallcaps">Catecismo de Heidelberg</h1>
            <hr class="fleuronColor"/>
    <p>O Catecismo de Heidelberg é o segundo dos padrões doutrinários da Igreja Reformada e originou-se no ano de 1563 na cidade de Heidelberg (daí seu nome), capital do eleitorado alemão do Palatinado. O príncipe eleitor Frederico III, que se tornou calvinista em 1560, encarregou Zacarias Ursinus (professor da faculdade de teologia de Heidelberg) e Caspar Olevianus, pregador da corte, de prepararem um manual de instrução doutrinária para consolidar a fé reformada em seus domínios. O novo catecismo foi aprovado e publicado em 1563. O sucesso foi imediato e em sua terceira edição as perguntas e respostas foram agrupadas em 52 <em>Dias do Senhor</em>, de modo que seu conteúdo pudesse ser estudado ao longo de um ano.</p>

<h3>Domingo 1</h3>

<h4>Qual é o seu único conforto na vida e na morte?</h4>

<p> O meu único conforto é que – corpo e alma, na vida e na morte<sup>1</sup> – não pertenço a mim mesmo,<sup>2</sup> mas ao meu fiel Salvador, Jesus Cristo,<sup>3</sup> que, ao preço do seu próprio sangue, pagou<sup>4</sup> totalmente por todos os meus pecados e me libertou completamente do domínio do pecado.<sup>5</sup> Ele me protege tão bem<sup>6</sup> que, contra a vontade de meu Pai do céu, não perderei nem um fio de cabelo.<sup>7</sup> Na verdade, tudo coopera para meu bem e o seu propósito é para a minha salvação.<sup>8</sup> Portanto, pelo seu Espírito Santo ele também me garante a vida eterna<sup>9</sup> e me torna disposto a viver para ele daqui em diante, de todo o coração.<sup>10</sup></p>


<ol>
    <li>1Co 3:23; Tt 2:14</li>
    <li>Rm 14:8; 1Ts 5:9,10</li>
    <li>1Co 6:19,20</li>
    <li>1Pe 1:18,19; 1Jo 1:7; 1Jo 2:2,12</li>
    <li>Jo 8:34–36; Hb 2:14,15; 1Jo 3:8</li>
    <li>Jo 6:39; Jo 10:27–30; 2Ts 3:3; 1Pe 1:5</li>
    <li>Mt 10:29,30; Lc 21:18</li>
    <li>Rm 8:28</li>
    <li>Rm 8:16; 2Co 1:22; 2 Co 5:5; Ef 1:13–14</li>
    <li>Rm 8:14; 1Jo 3:3</li>
</ol>

<h4>O que você deve saber para viver e morrer nesse fundamento?</h4>

<p>Primeiro: como são grandes meus pecados e minha miséria.<sup>1</sup> Segundo: como sou salvo de meus pecados e de minha miséria.<sup>2</sup> Terceiro: como devo ser grato a Deus por tal salvação.<sup>3</sup></p>
<ol>
    <li>Mt 9:12; Jo 9:41; Rm 3:10; 1Jo 1:9,10</li>
    <li>Lc 24:46,47; Jo 17:3; At 4:12; At 10:43; 1Co 6:11; Tt 3:3–7</li>
    <li>Sl 50:14,15; Sl 116:12,13; Mt 5:16; Rm 6:12,13; Ef 5:10; 2Tm 2:15; 1Pe 2:9,12. Veja também Mt 11:28–30; Ef 5:8</li>
</ol>


<hr />
<h2>Parte 1: Nossa miséria</h2>

<h3>Domingo 2</h3>

<h4>Como você conhece sua miséria?</h4>

<p>Pela Lei de Deus.<sup>1</sup></p>

<ol>
    <li>Rm 3:20</li>
</ol>
<h4>O que a Lei de Deus exige de nós?</h4>

<p>Cristo nos ensina isso, em um resumo, em Mateus 22:37–40: <quote>&ldquo;Amarás o Senhor teu Deus de todo o teu coração, de toda a tua alma e de todo o teu entendimento. Este é o grande e primeiro mandamento. O segundo, semelhante a este, é: Amarás o teu próximo como a ti mesmo. Destes dois mandamentos dependem toda a lei e os profetas&rdquo;</quote>.<sup>1</sup></p>
<ol>
    <li>Lv 19:18; Dt 6:5; Mc 12:30,31; Lc 10:27</li>
</ol>

<h4>Você pode guardar essa lei perfeitamente?</h4>

<p>Não, não posso,<sup>1</sup> porque por natureza sou inclinado a odiar a Deus e o meu próximo.<sup>2</sup></p>


<ol>
    <li>Rm 3:10,20,23; 1Jo 1:8,10</li>
    <li>Gn 6:5; Gn 8:21; Jr 17:9; Rm 7:23; Rm 8:7; Ef 2:3; Tt 3:3</li>
</ol>

<h3>Domingo 3</h3>


<h4>Deus criou o homem tão mau e perverso?</h4>

<p>Não, Deus criou o homem bom<sup>1</sup> e à sua imagem,<sup>2</sup> isto é, em verdadeira justiça e santidade para conhecer corretamente a Deus, seu Criador, amá-Lo de todo o coração e viver com Ele em eterna felicidade, para louvá-Lo e glorificá-Lo.<sup>3</sup></p>
<ol>
    <li>Gn 1:31</li>
    <li>Gn 1:26,27</li>
    <li>2Co 3:18; Ef 4:24; Cl 3:10</li>
</ol>

<h4>De onde vem, então, esta natureza corrompida do homem?</h4>

<p>Da queda e desobediência de nossos primeiros pais, Adão e Eva, no paraíso.<sup>1</sup> Ali, nossa natureza tornou-se tão envenenada, que todos nós somos concebidos em pecado e nascemos nele.<sup>2</sup></p>
<ol>
    <li>Gn 3; Rm 5:12,18,19</li>
    <li>Sl 51:5; Jo 3:6</li>
</ol>

<h4>Somos tão corrompidos que não conseguimos fazer bem algum e somos inclinados para todo mal?</h4>
<p>Somos sim,<sup>1</sup> se não nascermos de novo pelo Espírito de Deus.<sup>2</sup></p>

<ol>
    <li>Gn 6:5; Gn 8:21; Jó 14:4; Jó 15:14,16,35; Is 53:6; Tt 3:3</li>
    <li>Jo 3:3,5; 1Co 12:3; 2Co 3:5</li>
</ol>

<h3>Domingo 4</h3>

<h4>Então, Deus exige do homem, em sua lei, o que este não pode cumprir. Isso não é injusto?</h4>

<p>Não, pois Deus criou o homem de tal maneira que este pudesse cumprir a lei.<sup>1</sup> O homem, porém, instigado pelo diabo e por sua própria rebeldia, privou a si mesmo e a todos os seus descendentes desses dons.<sup>2</sup></p>
<ol>
    <li>Gn 1:27; Ef 4:24</li>
    <li>Gn 3:4–6; Rm 5:12; 1Tm 2:13,14</li>
</ol>

<h4>Deus deixa sem castigo essa desobediência e rebeldia?</h4>

<p>Não, não deixa, porque ele se ira terrivelmente tanto contra os pecados em que nascemos como contra os que cometemos, e quer castigá-los por justo julgamento, agora, nesta vida, e na futura.<sup>1</sup></p>
<p>Ele mesmo declarou: <quote>Maldito todo aquele que não permanece em todas as coisas escritas no livro da lei, para praticá-las</quote> <cite>(Gl 3:10)</cite>.<sup>2</sup></p>
<ol>
    <li>Gn 2:17; Êx 20:5; Êx 34:7; Sl 5:5; Na 1:2; Rm 1:18; Rm 5:12; Ef 5:6; Hb 9:27</li>
    <li>Dt 27:26</li>
</ol>

<h4>Mas Deus não é também misericordioso?</h4>

<p>Deus, na verdade, é misericordioso,<sup>1</sup> mas também é justo.<sup>2</sup> Por isso, sua justiça exige que o pecado cometido contra a sua suprema majestade seja castigado também com a pena máxima, quer dizer, com o castigo eterno, em corpo e alma.<sup>3</sup></p>
<ol>
    <li>Êx 20:6; Êx 34:6,7</li>
    <li>Êx 20:5; Êx 23:7; Êx 34:7; Sl 7:9</li>
    <li>Na 1:2,3; 2Ts 1:9</li>
</ol>

<hr />
<h2>Parte 2: Nossa salvação</h2>

<h3>Domingo 5</h3>

<h4>Então, conforme o justo julgamento de Deus, merecemos castigo nesta vida e na futura. Como podemos escapar desse castigo e, de novo, ser aceitos por Deus em graça?</h4>

<p>Deus quer que sua justiça seja cumprida.<sup>1</sup> Por isso, nós mesmos devemos satisfazer essa justiça, ou uma outra pessoa por nós.<sup>2</sup></p>

<ol>
    <li>Gn 2:17; Êx 20:5; Êx 23:7; Ez 18:4; Hb 10:30</li>
    <li>Mt 5:26; Rm 8:3,4</li>
</ol>

<h4>Nós mesmos podemos satisfazer essa justiça?</h4>

<p>De maneira alguma. Pelo contrário, aumentamos a cada dia a nossa dívida com Deus.<sup>1</sup></p>

<ol>
    <li>Jó 4:18,19; Jó 9:2,3; Jó 15:16; Sl 130:3; Mt 6:12; Mt 16:26; Mt 18:25</li>
</ol>

<h4>Será que uma criatura, sendo apenas criatura, pode pagar por nós?</h4>

<p>Não, não pode. Primeiro: porque Deus não quer castigar uma outra criatura pela dívida do homem.<sup>1</sup> Segundo: porque tal criatura não poderia suportar o peso da ira eterna de Deus contra o pecado e dela livrar outros.<sup>2</sup></p>

<ol>
    <li>Gn 3:17; Ez 18:4</li>
    <li>Sl 130:3; Na 1:6</li>
</ol>

<h4>Que tipo de mediador e salvador, então, devemos buscar?</h4>

<p>O mediador deve ser um homem verdadeiro<sup>1</sup> e justo,<sup>2</sup> contudo, mais poderoso que todas as criaturas; portanto, alguém que é, ao mesmo tempo, verdadeiro Deus.<sup>3</sup></p>


<ol>
    <li>1Co 15:21</li>
    <li>Hb 7:26</li>
    <li>Is 7:14; Is 9:6; Jr 23:6; Lc 11:22; Rm 8:3,4</li>
</ol>


<h3>Domingo 6</h3>


<h4>Por que o mediador deve ser homem verdadeiro e justo?</h4>

<p>Deve ser verdadeiro homem porque a justiça de Deus exige que o homem pague o pecado do homem.<sup>1</sup></p>
<p>Deve ser justo porque alguém que tem seus próprios pecados não pode pagar por outros.<sup>2</sup></p>
<ol>
    <li>Is 53:3–5; Jr 33:15; Ez 18:4,20; Rm 5:12–15; 1Co 15:21; Hb 2:14–16</li>
    <li>Sl 49:7; Hb 7:26,27; 1Pe 3:18</li>
</ol>

<h4>Por que o mediador deve ser, ao mesmo tempo, verdadeiro Deus?</h4>
<p>Porque somente sendo verdadeiro Deus<sup>1</sup> ele pode suportar,<sup>2</sup> como homem, o peso da ira<sup>3</sup> de Deus, conquistar e restituir para nós a justiça e a vida.<sup>4</sup></p>

<ol>
    <li>Is 9:6; Rm 1:4; Hb 1:3</li>
    <li>Is 53:4,11</li>
    <li>Dt 4:24; Sl 130:3; Na 1:6</li>
    <li>Is 53:5,11; Is 54:8; Jo 3:16; At 20:28; 1Pe 3:18</li>
</ol>

<h4>Quem é esse mediador que, ao mesmo tempo, é verdadeiro Deus,<sup>1</sup> homem verdadeiro<sup>2</sup> e justo?<sup>3</sup></h4>
<p>Nosso Senhor Jesus Cristo,<sup>4</sup> que nos foi dado graciosamente para completa salvação e justiça.<sup>5</sup></p>

<ol>
    <li>Jr 23:6; Mt 3:1; Rm 8:3; Gl 4:4; 1Jo 5:20</li>
    <li>Lc 1:42; Lc 2:6,7; Rm 1:3; Fp 2:7; Hb 2:14,17; Hb 4:15</li>
    <li>Is 53:9,11; Jr 23:5; Lc 1:35; Jo 8:46; Hb 4:15; Hb 7:26; 1Pe 1:19; 1Pe 2:22; 1Pe 3:18</li>
    <li>Mt 1:23; Lc 2:11; Jo 1:1,14; Jo 14:6; Rm 9:5; 1Tm 2:5; 1Tm 3:16; Hb 2:9</li>
    <li>1Co 1:30; 2Co 5:21</li>
</ol>

<h4>Como você sabe isso?</h4>
<p>Pelo santo evangelho que o próprio Deus, de início, revelou no paraíso.<sup>1</sup> Depois mandou, anunciá-lo pelos santos patriarcas<sup>2</sup> e profetas,<sup>3</sup> e o prefigurou por meio dos sacrifícios e das outras cerimônias do Antigo Testamento.<sup>4</sup></p>
<p>Finalmente o cumpriu por seu único Filho.<sup>5</sup></p>

<ol>
    <li>Gn 3:15</li>
    <li>Gn 12:3; Gn 22:18; Gn 26:4; Gn 49:10</li>
    <li>Is 42:1–4; Is 43:25; Is 49:6; Is 53; Jr 23:5,6; Jr 31:32,33; Mq 7:18–20; Jo 5:46; At 3:22–24; At 10:43; Rm 1:2; Hb 1:1</li>
    <li>Cl 2:17; Hb 10:1,7</li>
    <li>Rm 10:4; Gl 3:24; Gl 4:4,5; Cl 2:17</li>
</ol>


<h3>Domingo 7</h3>

<h4>Todos os homens, então, tornam-se salvos por Cristo, assim como pereceram em Adão?</h4>
<p>Não.<sup>1</sup> Somente aqueles que, pela verdadeira fé, são unidos a Cristo e aceitam todos os seus benefícios.<sup>2</sup></p>
<ol>
    <li>Mt 7:14; Mt 22:14</li>
    <li>Sl 2:12; Mc 16:16; Jo 1:12,13; Jo 3:16,18,36; Rm 3:22; Rm 11:20; Hb 4:2,3; Hb 5:9; Hb 10:39; Hb 11:6</li>
</ol>

<h4>O que é a verdadeira fé?</h4>

<p>A verdadeira fé é o conhecimento e a certeza de que é verdade tudo o que Deus nos revelou em sua Palavra.<sup>1</sup> É também a plena confiança<sup>2</sup> de que Deus concedeu, por pura graça, não só a outros, mas também a mim, a remissão dos pecados, a justiça eterna e a salvação,<sup>3</sup> somente pelos méritos de Cristo.<sup>4</sup></p>
<p>O Espírito Santo<sup>5</sup> opera esta fé em meu coração, por meio do evangelho.<sup>6</sup></p>

<ol>
    <li>Rm 4:20,21; Hb 11:1,3; Tg 1:6</li>
    <li>Sl 9:10; Rm 4:16–21; Rm 5:1; Rm 10:10; Ef 3:12; Hb 4:16</li>
    <li>Hc 2:4; At 10:43; Rm 1:17; Gl 3:11; Hb 10:10,38</li>
    <li>Lc 1:77,78; Jo 20:31; At 10:43; Rm 3:24; Rm 5:19; Gl 2:16</li>
    <li>Mt 16:17; Jo 3:5; Jo 6:29; At 16:14; 2Co 4:13; Ef 2:8; Fp 1:29</li>
    <li>Mc 16:15; At 10:44; At 16:14; Rm 1:16; Rm 10:17; 1Co 1:21</li>
</ol>

<h4>Em que um cristão deve crer?</h4>

<p>Em tudo o que nos é prometido no evangelho. O Credo Apostólico, a confissão da nossa fé cristão, universal e indubitável, apresenta um resumo disso.<sup>1</sup></p>
<ol>
    <li>Mc 1:15; Jo 20:31</li>
</ol>

<h4>O que dizem os artigos desse Credo?</h4>

    <ol class="roman">
    <li>1. Creio em Deus Pai, Todo-poderoso, Criador do céu e da terra; </li>
    <li>2. e em Jesus Cristo, seu único Filho, nosso Senhor; <br />
        3. que foi concebido pelo Espírito Santo, nasceu da virgem Maria; <br />
        4. padeceu sob o poder de Pôncio Pilatos, foi crucificado, morto e sepultado, desceu ao inferno; <br />
        5. no terceiro dia ressurgiu dos mortos; <br />
        6. subiu ao céu e esta sentado à mão direita de Deus Pai Todo-poderoso; <br />
        7. donde há de vir julgar os vivos e os mortos.</li>
    <li>8. Creio no Espírito Santo; <br />
        9. na santa igreja universal de Cristo, na comunhão dos santos; <br />
        10. na remissão dos pecados; <br />
        11. na ressurreição do corpo; <br />
        12. e na vida eterna.</li>
</ol>

<h3>Domingo 8</h3>
<h4>Como se divide este Credo?</h4>

<p>Em três partes. A primeira trata de Deus Pai e da nossa criação. A segunda de Deus Filho e da nossa salvação. A terceira de Deus Espírito Santo e da nossa santificação.</p>

<h4>Por que você fala de três Pessoas: Pai, Filho e Espírito Santo, visto que há um só Deus<sup>1</sup>?</h4>

<p>Porque Deus se revelou, em sua Palavra, de tal maneira que estas três Pessoas distintas são o único, verdadeiro e eterno Deus.<sup>2</sup></p>


<ol>
    <li>Dt 6:4; Is 44:6; Is 45:5; 1Co 8:4,6; Ef 4:5,6</li>
    <li>Gn 1:2,3; Is 61:1; Mt 3:16,17; Mt 28:19; Lc 1:5; Lc 4:18; Jo 14:26; Jo 15:26; At 2:32,33; 2Co 13:13; Gl 4:6; Ef 2:18; Tt 3:4–6</li>

</ol>    
<hr />
<h2>Deus Pai e nossa criação</h2>


<h3>Domingo 9</h3>


<h4>Em que você crê quando diz: &ldquo;Creio em Deus Pai, Todo-Poderoso, Criador do céu e da terra&rdquo;?</h4>

<p>Creio que o eterno Pai de nosso Senhor Jesus Cristo criou, do nada, o céu, a terra e tudo o que neles há<sup>1</sup> e ainda os sustenta e governa por seu eterno conselho e providência.<sup>2</sup> Ele é também meu Deus e meu Pai, por causa de seu Filho Cristo.<sup>3</sup> NEle confio de tal maneira, que não duvido que dará tudo o que for necessário para meu corpo e minha alma<sup>4</sup>; e que Ele transformará em bem todo mal que me enviar, nesta vida conturbada.<sup>5</sup> Tudo isto Ele pode fazer como Deus todo-poderoso<sup>6</sup> e quer fazer como Pai fiel.<sup>7</sup></p>


<ol>
    <li>Gn 1:1; Gn 2:3; Êx 20:11; Jó 33:4; Jó 38:4–11; Sl 33:6; Is 40:26; At 4:24; At 14:15</li>
    <li>Sl 104:2–5,27–30; Sl 115:3; Mt 10:29,30; Rm 11:36; Ef 1:11</li>
    <li>Jo 1:12; Rm 8:15; Gl 4:5–7; Ef 1:5</li>
    <li>Sl 55:22; Mt 6:25,26; Lc 12:22–24</li>
    <li>Rm 8:28</li>
    <li>Rm 8:37–39; Rm 10:12; Ap 1:8</li>
    <li>Mt 6:32,33; Mt 7:9–11</li>
</ol>


<h3>Domingo 10</h3>

<h4>O que é a providência de Deus?</h4>

<p>É a força Todo-Poderoso e presente,<sup>1</sup> com que Deus, pela sua mão, sustenta e governa o céu, a terra e todas as criaturas.<sup>2</sup> Assim, ervas e plantas, chuva e seca,<sup>3</sup> anos frutíferos e infrutíferos, comida e bebida, saúde e doença, riqueza e pobreza e todas as coisas<sup>4</sup> não nos sobrevêm por acaso, mas de sua mão paternal.<sup>5</sup></p>


<ol>
    <li>Sl 94:9,10; Is 29:15,16; Jr 23:23,24; Ez 8:12; Mt 17:27; At 17:25–28</li>
    <li>Hb 1:3</li>
    <li>Jr 5:24; At 14:17</li>
    <li>Pv 22:2; Jo 9:3</li>
    <li>Pv 16:33; Mt 10:29</li>
</ol>

<h4>Para que serve saber da criação e da providência de Deus?</h4>

<p>Para que tenhamos paciência<sup>1</sup> em toda adversidade e mostremos gratidão<sup>2</sup> em toda prosperidade e para que, quanto ao futuro, tenhamos a firme confiança em nosso fiel Deus e Pai, de que criatura alguma nos pode separar do amor dEle.<sup>3</sup> Porque todas as criaturas estão na mão de Deus, de tal maneira que sem a vontade dEle não podem agir nem se mover.<sup>4</sup></p>


<ol>
    <li>Jó 1:21,22; Sl 39:9; Rm 5:3,4; Tg 1:3</li>
    <li>Dt 8:10; 1Ts 5:18</li>
    <li>Sl 55:22; Rm 5: 4,5; Rm 8:38,39</li>
    <li>Jó 1:12; Jó 2:6; Pv 21:1; At 17:25–28</li>
</ol>

<hr />
<h2>Deus Filho e nossa salvação</h2>


<h3>Domingo 11</h3>


<h4>O nome &ldquo;Jesus&rdquo; significa &ldquo;Salvador.&rdquo; Por que o Filho de Deus tem este nome?</h4>

<p>Porque Ele nos salva de todos os nossos pecados<sup>1</sup> e porque, em ninguém mais, devemos buscar ou podemos encontrar salvação.<sup>2</sup></p>

<ol>
    <li>Mt 1:21; Hb 7:25</li>
    <li>Is 43:11; Jo 15:4,5; At 4:11,12; 1Tm 2:5; 1Jo 5:11,12</li>
</ol>

<h4>Será que aqueles que buscam, o bem e a salvação nos assim chamados &ldquo;santos&rdquo;, ou em si mesmos ou em qualquer lugar, realmente crêem no único Salvador?</h4>

<p>Não, não crêem, pois na prática negam o único Salvador Jesus, ainda que falem tanto dEle.<sup>1</sup> Pois das duas, uma: ou Jesus não é o perfeito Salvador, ou aqueles que O aceitam como Salvador com verdadeira fé, encontram nEle tudo o que é necessário para a salvação.<sup>2</sup></p>


<ol>
    <li>1Co 1:13,30,31; Gl 5:4</li>
    <li>Is 9:7; Jo 1:16; Cl 1:19,20; Cl 2:10; Hb 12:2; 1Jo 1:7</li>
</ol>

<h3>Domingo 12</h3>

<h4>O nome &ldquo;Cristo&rdquo; significa &ldquo;Ungido.&rdquo; Por que Jesus tem também este nome?</h4>

<p>Porque Ele foi ordenado por Deus Pai e ungido<sup>1</sup> com o Espírito Santo para ser nosso supremo Profeta e Mestre, nosso único Sumo Sacerdote e nosso eterno Rei.</p>

<p>Como Profeta Ele nos revelou plenamente o plano de Deus para nossa salvaçao<sup>2</sup>; como Sumo Sacerdote Ele nos resgatou pelo único sacrifício de seu corpo<sup>3</sup> e, continuamente, intercede por nós junto ao Pai<sup>4</sup>; como Rei Ele nos governa por sua Palavra e Espírito e nos protege e guarda na salvação<sup>5</sup> que Ele conquistou para nós.</p>


<ol>
    <li>Sl 45:7; Is 61:1; Lc 4:18; At 10:38; Hb 1:9</li>
    <li>Dt 18:15; Is 55:4; Mt 11:27; Jo 1:18; Jo 15:15; At 3:22</li>
    <li>Sl 110:4; Hb 7:21; Hb 9:12,14,28; Hb 10:12,14</li>
    <li>Rm 8:34; Hb 7:25; Hb 9:24; 1Jo 2:1</li>
    <li>Sl 2:6; Zc 9:9; Mt 21:5; Mt 28:18; Lc 1:33; Jo 10:28; Ap 12:10,11</li>
</ol>

<h4>Por que você e chamado cristão<sup>1</sup>?</h4>

<p>Porque pela fé sou membro de Cristo e, por isso, também sou ungido<sup>2</sup> para ser profeta, sacerdote e rei. Como profeta confesso o nome dEle<sup>3</sup>; Como sacerdote ofereço minha vida a Ele como sacrifício vivo de gratidão<sup>4</sup>; e como rei combato,<sup>5</sup> nesta vida, o pecado e o diabo, de livre consciência, e depois, na vida eterna, vou reinar com Ele sobre todas as criaturas.<sup>6</sup></p>


<ol>
    <li>At 11:26</li>
    <li>Is 59:21; Jl 2:28; At 2:17; 1Co 6:15; 1Jo 2:27</li>
    <li>Mt 10:32,33; Rm 10:10</li>
    <li>Êx 19:6; Rm 12:1; 1Pe 2:5; Ap 1:6; Ap 5:8,10</li>
    <li>Rm 6:12,13; Gl 5:16,17; Ef 6:11; 1Tm 1:18,19; 1Pe 2:9,11</li>
    <li>2Tm 2:12; Ap 22:5</li>
</ol>


<h3>Domingo 13</h3>


<h4>Por que Cristo é chamado &ldquo;o único Filho de Deus&rdquo;, se nós também somos filhos de Deus?</h4>

<p>Porque só Cristo é, por natureza, o Filho eterno de Deus.<sup>1</sup> Nós, porém, somos filhos adotivos de Deus,<sup>2</sup> pela graça, por causa de Cristo.</p>


<ol>
    <li>Jó 1:14,18; Jo 3:16; Rm 8:32; Hb 1:1,2; 1Jo 4:9</li>
    <li>Jo 1:12; Rm 8:15–17; Gl 4:6; Ef 1:5,6</li>
</ol>

<h4>Por que você chama Cristo &ldquo;nosso Senhor&rdquo;?</h4>

<p>Porque Ele nos comprou e resgatou, corpo e alma, dos nossos pecados e de todo o domínio do diabo, não com ouro ou prata, mas com seu precioso sangue. Assim pertencemos a Ele.<sup>1</sup></p>

<ol>
    <li>Jo 20:28; 1Co 6:20; 1Co 7:23; Ef 1:7; 1Tm 2:6; 1Pe 1:18,19; 1Pe 2:9</li>
</ol>


<h3>Domingo 14</h3>


<h4>O que você entende, quando diz que Cristo &ldquo;foi concebido pelo Espírito Santo e nasceu da virgem Maria&rdquo;?</h4>

<p>Entendo que o eterno Filho de Deus, que é e permanece verdadeiro e eterno Deus,<sup>1</sup> tornou-se verdadeiro homem,<sup>2</sup> da carne e do sangue da virgem Maria,<sup>3</sup> por obra do Espírito Santo. Assim Ele é, de fato, o descendente de Davi<sup>4</sup> igual a seus irmãos em tudo, mas sem pecado.<sup>5</sup></p>


<ol>
    <li>Mt 1:23; Mt 3:17; Mt 16:16; Mt 17:5; Mc 1:11; Jo 1:1Jo 17:3,5; Jo 20:28; Rm 1:3,4; Rm 9:5; Fp 2:6; Cl 1:15,16; Tt 2:13; Hb 1:3; 1Jo 5:20</li>
    <li>Mt 1:18,20; Lc 1:35</li>
    <li>Lc 1:31,42,43; Jo 1:14; Gl 4:4</li>
    <li>2Sm 7:12; Sl 132:11; Mt 1:1; Lc 1:32; At 2:30,31; Rm 1:3</li>
    <li>Fp 2:7; Hb 2:14,17; Hb 4:15; Hb 7:26,27</li>
</ol>

<h4>Que importância tem para você Cristo ter sido concebido e nascido sem pecado?</h4>

<p>Que Ele é nosso Mediador<sup>1</sup> e com sua inocência e perfeita santidade, cobre diante de Deus meu pecado<sup>2</sup> no qual fui concebido e nascido.</p>


<ol>
    <li>Hb 2:16–18; Hb 7:26,27</li>
    <li>Sl 32:1; Is 53:11; Rm 8:3,4; 1Co 1:30,31; Gl 4:4,5; 1Pe 1:18,19; 1Pe 3:18</li>
</ol>


<h3>Domingo 15</h3>

 
<h4>que você quer dizer com a palavra &ldquo;padeceu&rdquo;?</h4>

<p>Que Cristo, em corpo e alma, durante toda a sua vida na terra, mas principalmente no final, suportou a ira de Deus contra os pecados de todo o gênero humano.<sup>1</sup> Por este sofrimento, como o único sacrifício propiciatório,<sup>2</sup> Ele salvou, da condenação eterna de Deus, nosso corpo e alma<sup>3</sup> e conquistou para nós a graça de Deus, a justiça e a vida eterna.<sup>4</sup></p>


<ol>
    <li>Is 53:4,12; 1Tm 2:6; 1Pe 2:24; 1Pe 3:18</li>
    <li>Is 53:10; Rm 3:25; 1Co 5:7; Ef 5:2; Hb 9:28; Hb 10:14; 1Jo 2:2; 1Jo 4:10</li>
    <li>Gl 3:13; Cl 1:13; Hb 9:12; 1Pe 1:18,19</li>
    <li>Jo 3:16; Jo 6:51; 2Co 5:21; Hb 9:15; Hb 10:19</li>
</ol>

<h4>Por que Ele padeceu &ldquo;sob Pôncio Pilatos&rdquo;?</h4>

<p>Cristo, embora julgado inocente, foi condenado pelo juiz oficial,<sup>1</sup> para que nos libertasse do severo juízo de Deus que devia cair sobre nós.<sup>2</sup></p>


<ol>
    <li>Mt 27:24; Lc 23:13–15; Jo 18:38; Jo 19:4; Jo 19:11</li>
    <li>Is 53:4,5; 2Co 5:21; Gl 3:13</li>
</ol>

<h4>Cristo &ldquo;foi crucificado.&rdquo; Isto tem mais sentido do que morrer de outra maneira?</h4>

<p>Tem sim, porque pela crucificação tenho certeza de que Ele tomou sobre si<sup>1</sup> a maldição que pesava sobre mim. Pois a morte da cruz era maldita por Deus.<sup>2</sup></p>


<ol>
    <li>Gl 3:13. </li>
    <li>Dt 21:23</li>
</ol>


<h3>Domingo 16</h3>

<h4>Por que Cristo devia sofrer a morte?</h4>
<p>Porque a justiça e a verdade de Deus<sup>1</sup> exigiam a morte do Filho de Deus; não houve outro meio de pagar nossos pecados.<sup>2</sup></p>


<ol>
    <li>Gn 2:17</li>
    <li>Rm 8:3,4; Fp 2:8; Hb 2:9,14,15</li>
</ol>

<h4>Por que Ele foi &ldquo;sepultado&rdquo;?</h4>

<p>Para dar testemunho de que estava realmente morto.<sup>1</sup></p>
<ol>
    <li>
Mt 27:59,60; Lc 23:53; Jo 19:40–42; At 13:29; 1Co 15:3,4.
</li>
</ol>

<h4>Se Cristo morreu por nós, por que devemos nós morrer também?</h4>

<p>Nossa morte não é para pagar nossos pecados,<sup>1</sup> mas somente significa que morremos para o pecado e que passamos para a vida eterna.<sup>2</sup></p>


<ol>
    <li>Mc 8:37</li>
    <li>Jo 5:24; Rm 7:24,25; Fp 1:23</li>
</ol>

<h4>Que importância tem, para nós, o sacrifício e a morte de Cristo na cruz?</h4>

<p>Pelo poder de Cristo, nosso velho homem é crucificado, morto e sepultado com Ele,<sup>1</sup> para que os maus desejos da carne não mais nos dominem,<sup>2</sup> mas que nos ofereçamos a Ele, como sacrifício de gratidão.<sup>3</sup></p>
<ol>
    <li>Rm 6:6</li>
    <li>Rm 6:8,11,12</li>
    <li>Rm 12:1</li>
</ol>

<h4>Por que se acrescenta: &ldquo;desceu ao inferno&rdquo;?</h4>
<p>Porque meu Senhor Jesus Cristo sofreu, principalmente na cruz inexprimíveis angústias, dores e terrores.<sup>1</sup> Por isso, até nas minhas mais duras tentações, tenho a certeza de que Ele me libertou da angústia e do tormento do inferno.<sup>2</sup></p>
<ol>
    <li>Mt 26:38; Mt 27:46; Hb 5:7</li>
    <li>Is 53:5</li>
</ol>

<h3>Domingo 17</h3>
<h4>Que importância tem, para nós, a ressurreição de Cristo?</h4>
<p>Primeiro: pela ressurreição, Ele venceu a morte, para que nós pudéssemos participar da justiça, que Ele conquistou por sua morte.<sup>1</sup> Segundo: nos também, por seu poder, somos ressuscitados para a nova vida.<sup>2</sup> Terceiro: a ressurreição de Cristo é uma garantia de nossa ressurreição em glória.<sup>3</sup></p>
<ol>
    <li>Rm 4:25; 1Co 15:16–18; 1Pe 1:3</li>
    <li>Rm 6:4; Cl 3:1–3; Ef 2:4–6</li>
    <li> Rm 8:11; 1Co 15:20–22</li>
</ol>


<h3>Domingo 18</h3>
<h4>O que você quer dizer com as palavras: &ldquo;subiu ao céu&rdquo;?</h4>
<p>Que Cristo, à vista de seus discípulos, foi elevado da terra ao céu<sup>1</sup> e lá esta para nosso bem,<sup>2</sup> até que volte para julgar os vivos e os mortos.<sup>3</sup></p>
<ol>
    <li>Mt 16:19; Lc 24:51; At 1:9</li>
    <li>Rm 8:34; Ef 4:10; Cl 3:1; Hb 4:14; Hb 7:24,25; Hb 9:24</li>
    <li>Mt 24:30; At 1:11</li>
</ol>

<h4>Cristo, então, não está conosco até o fim do mundo, como nos prometeu<sup>1</sup>?</h4>

<p>Cristo é verdadeiro homem e verdadeiro Deus. Segundo sua natureza humana não está agora na terra,<sup>2</sup> mas segundo sua divindade, majestade, graça e Espirito jamais se afasta de nós.<sup>3</sup></p>
<ol>
    <li>Mt 28:20</li>
    <li>Mt 26:11; Jo 16:28; Jo 17:11; At 3:21; Hb 8:4</li>
    <li>Mt 28:20; Jo 14:16–18; Jo 16:13; Ef 4:8</li>
</ol>

<h4>Mas se a natureza humana não está em todo lugar onde a natureza divina está, as duas naturezas de Cristo não são separadas uma da outra?</h4>

<p>De maneira nenhuma; a natureza divina de Cristo não pode ser limitada e está presente em todo lugaPor isso, podemos concluir que a natureza divina dEle está na sua natureza humana e permanece pessoalmente unida a ela, embora também esteja fora dela.<sup>2</sup></p>
<ol>
    <li>Is 66:1; Jr 23:23,24; At 7:49; At 17:27,28</li>
    <li>Mt 28:6; Jo 3:13; Jo 11:15; Cl 2:8</li>
</ol>

<h4>Que importância tem, para nós, a ascensão de Cristo?</h4>
<p>Primeiro: Ele é, no céu, nosso Advogado junto a seu Pai.<sup>1</sup> Segundo: em Cristo temos nossa carne no céu, como garantia segura de que Ele, como nosso Cabeça, também nos levará para si, como seus membros.<sup>2</sup> Terceiro: Ele nos envia seu Espírito, como garantia,<sup>3</sup> pelo poder do Espírito buscamos as coisas que são do alto, onde Cristo está sentado a direita de Deus, e não as coisas que são da terra.<sup>4</sup></p>
<ol>
    <li>Rm 8:34; 1Jo 2:1</li>
    <li>Jo 14:2,3; Jo 17:24; Ef 2:6</li>
    <li>Jo 14:16; Jo 16:7; At 2:33; 2Co 1:22; 2Co 5:5</li>
    <li>Fp 3:20; Cl 3:1</li>
</ol>


<h3>Domingo 19</h3>
<h4>Por que se acrescenta: &ldquo;e está sentado à direita de Deus&rdquo;?</h4>
<p>Porque Cristo subiu ao céu para mani festar-se, lá mesmo, como o Cabeça de sua igreja cristã<sup>1</sup> e para governar tudo em nome de seu Pai.<sup>2</sup></p>
<ol>
    <li>Ef 1:20–23; Cl 1:18</li>
    <li>Mt 28:18; Jo 5:22</li>
</ol>

<h4>Que importância tem, para nós, essa glória de Cristo, nosso Cabeça?</h4>
<p>Primeiro: por seu Espírito Santo, Ele derrama sobre nós, seus membros, os dons celestiais.<sup>1</sup> Segundo: Ele nos defende e protege, por seu poder, contra todos os inimigos.<sup>2</sup></p>
<ol>
    <li>At 2:33; Ef 4:8,10–12</li>
    <li>Sl 2:9; Sl 110:1,2; Jo 10:28; Ef 4:8; Ap 12:5</li>
</ol>

<h4>Que consolo traz a você a volta de Cristo &ldquo;para julgar os vivos e os mortos&rdquo;?</h4>

<p>Que, em toda miséria e perseguição, espero, de cabeça erguida, o Juiz que vem do céu, a saber: o Cristo que antes se apresentou em meu lugar ao tribunal de Deus e tirou de mim toda a maldição.<sup>1</sup></p>

<p>Ele lançará, na condenação eterna, todos os seus e meus inimigos,<sup>2</sup> mas Ele me levará para si mesmo, com todos os eleitos na alegria e glória celestiais.<sup>3</sup></p>
<ol>
    <li>Lc 21:28; Rm 8:23,24; Fp 3:20; 1Ts 4:16; Tt 2:13</li>
    <li>Mt 25:41–43; 2Ts 1:6,8,9</li>
    <li>Mt 25:34–36; 2Ts 1:7,10</li>
</ol>

<hr />
<h2>Deus Espírito Santo e nossa santificação</h2>

<h3>Domingo 20</h3>
<h4>O que você crê sobre o Espírito Santo?</h4>
<p>Primeiro: creio que Ele é verdadeiro e eterno Deus com o Pai e o Filho.<sup>1</sup> Segundo: que Ele foi dado também a mim.<sup>2</sup> Por uma verdadeira fé, Ele me torna participante de Cristo e de todos os seus benefícios.<sup>3</sup> Ele me fortalece<sup>4</sup> e fica comigo para sempre.<sup>5</sup></p>
<ol>
    <li>Gn 1:2; At 5:3,4; 1Co 2:10; 1Co 3:16; 1Co 6:19</li>
    <li>Mt 28:19; 2Co 1:21,22 Gl 3:14; Gl 4:6; Ef 1:13</li>
    <li>Jo 16:14; 1Co 2:12; 1Pe 1:2</li>
    <li>Jo 15:26; At 9:31</li>
    <li>Jo 14:16,17; 1Pe 4:14</li>
</ol>


<h3>Domingo 21</h3>
<h4>O que você crê sobre &ldquo;a santa igreja universal de Cristo&rdquo;? </h4>
<p>Creio que o Filho de Deus<sup>1</sup> reúne, protege e conserva,<sup>2</sup> dentre todo o gênero humano,<sup>3</sup> sua comunidade<sup>4</sup> eleita para a vida eterna.<sup>5</sup> Isto Ele fez por seu Espírito e sua Palavra,<sup>6</sup> na unidade da verdadeira fe,<sup>7</sup> desde o princípio do mundo até o fim.<sup>8</sup> Creio que sou membro vivo<sup>9</sup> dessa igreja, agora e para sempre<sup>10</sup>).</p>
<ol>
    <li>Jo 10:11; Ef 4:11–13; Ef 5:25,26</li>
    <li>Sl 129:4,5; Mt 16:18; Jo 10:16,28</li>
    <li>Gn 26:4; Is 49:6; Rm 10:12,13; Ap 5:9</li>
    <li>Sl 111:1; At 20:28; Hb 12:22,23</li>
    <li>Rm 8:29,30; Ef 1:10–14; 1Pe 2:9</li>
    <li>Is 59:21; Rm 1:16; Rm 10:14–17; Ef 5:26</li>
    <li>Jo 17:21; At 2:42; Ef 4:3–6; 1Tm 3:15</li>
    <li>Is 59:21; 1Cor 11:26 </li>
    <li>Rm 8:10; 1Jo 3:14,19–21</li>
    <li>Sl 23:6; Jo 10:28; Rm 8:35–39; 1Co 1:8,9; 1Pe 1:5; 1Jo 2:19</li>
</ol>
<h4>Como você entende as palavras: &ldquo;a comunhão dos santos&rdquo;?</h4>
<p>Primeiro: entendo que todos os crentes, juntos e cada um por si, têm, como membros, comunhão com Cristo, o Senhor, e todos os seus ricos dons.<sup>1</sup> Segundo: que todos devem sentir-se obrigados a usar seus dons com vontade e alegria para o bem dos outros membros.<sup>2</sup></p>
<ol>
    <li>Rm 8:32; 1Co 6:17; 1Co 12:12,13; 1Jo 1:3</li>
    <li>1Co 12:21; 1Co 13:1–7; Fp 2:2–5</li>
</ol>

<h4>O que você crê sobre &ldquo;a remissão dos pecados&rdquo;?</h4>
<p>Creio que Deus, por causa da satisfação em Cristo, jamais quer lembrar-se de meus pecados<sup>1</sup> e de minha natureza pecaminosa,<sup>2</sup> que devo combater durante toda a minha vida. Mas Ele me dá a justiça de Cristo,<sup>3</sup> pela graça, e assim nunca mais serei condenado por Deus.<sup>4</sup></p>
<ol>
    <li>Sl 103:3,10,12; Jr 31:34; Mq 7:19; 2Co 5:19</li>
    <li>Rm 7:23–25</li>
    <li>2Co 5:21; 1Jo 1:7; 1Jo 2:1,2</li>
    <li>Jo 3:18; Jo 5:24</li>
</ol>

<h3>Domingo 22</h3>
<h4>Que consolo traz a você &ldquo;a ressurreição da carne&rdquo;?</h4>
<p>Meu consolo é que depois desta vida minha alma será imediatamente elevada para Cristo, seu Cabeça.<sup>1</sup> E que também esta minha carne, ressuscitada pelo poder de Cristo, será unida novamente à minha alma e se tornará semelhante ao corpo glorioso de Cristo.<sup>2</sup></p>

<ol>
    <li>Lc 16:22; Lc 20:37,38; Lc 23:43; Fp 1:21,23; Ap 14:13</li>
    <li>Jó 19:25–27; 1Co 15:53,54; Fp 3:21; 1Jo 3:2</li>
</ol>
<h4>Que consolo traz a você o artigo sobre a vida eterna ?</h4>
<p>Meu consolo é que, como já percebo no meu coração o início da alegria eterna,<sup>1</sup> depois desta vida terei a salvação perfeita. Esta salvação nenhum olho jamais viu, nenhum ouvido ouviu e jamais surgiu no coração de alguém. Então louvarei a Deus eternamente.<sup>2</sup></p>
<ol>
    <li>Jo 17 3; 2Co 5:2,3</li>
    <li>Jo 17:24; 1Co 2:9</li>
<hr /></ol>
<h2>A justificação</h2>

<h3>Domingo 23</h3>
<h4>Mas que proveito tem sua fé no Evangelho?</h4>
<p>O proveito é que sou justo perante Deus, em Cristo, e herdeiro da vida eterna.<sup>1</sup></p>
<ol>
    <li>Hc 2:4; Jo 3:36; Rm 1:17</li>
</ol>

<h4>Como você é justo perante Deus?</h4>
<p>Somente por verdadeira fé em Jesus Cristo.<sup>1</sup> Mesmo que minha consciência me acuse de ter pecado gravemente contra todos os mandamentos de Deus, e de não ter guardado nenhum deles, e de ser ainda inclinado a todo mal,<sup>2</sup> todavia Deus me dá, sem nenhum mérito meu, por pura graça,<sup>3</sup> a perfeita satisfação, a justiça e a santidade de Cristo.<sup>4</sup> Deus me trata<sup>5</sup> como se eu nunca tivesse cometido pecado algum ou jamais tivesse sido pecador; e, como se pessoalmente eu tivesse cumprido toda a obediência que Cristo cumpriu por mim.<sup>6</sup> Este benefício é meu somente se eu o aceitar por fé, de todo o coração.<sup>7</sup></p>

<ol>
    <li>Rm 3:21–26; Rm 5:1,2; Gl 2:16; Ef 2:8,9; Fp 3:9</li>
    <li>Rm 3:9; Rm 7:23</li>
    <li>Dt 9:6; Ez 36:22; Rm 3:24; Rm 7:23–25; Ef 2:8; Tt 3:5</li>
    <li>1Jo 2:1,2</li>
    <li>Rm 4:4–8; 2Co 5:19</li>
    <li>2Co 5:21</li>
    <li>Jo 3:18; Rm 3:22</li>
</ol>

<h4>Por que você diz que é justo somente pela fé?</h4>
<p>Eu o digo não porque sou agradável a Deus graças ao valor da minha fé, mas porque somente a satisfação por Cristo e a justiça e santidade dEle me justificam perante Deus.<sup>1</sup> Somente pela fé posso aceitar e possuir esta justificação.<sup>2</sup></p>

<ol>
    <li>1Co 1:30; 1Co 2:2</li>
    <li>1Jo 5:10</li>
</ol>


<h3>Domingo 24</h3>
<h4>Mas por que nossas boas obras não nos podem justificar perante Deus, pelo menos em parte?</h4>
<p>Porque a justiça que pode subsistir perante o juízo de Deus deve ser absolutamente perfeita e completamente conforme a lei de Deus.<sup>1</sup> Entretanto, nesta vida, todas as nossas obras, até as melhores, são imperfeitas e manchadas por pecados.<sup>2</sup></p>
<ol>
    <li>Dt 27:26; Gl 3:10</li>
    <li>Is 64:6. </li>
</ol>

<h4>Nossas boas obras, então, não têm mérito? Deus não promete recompensá-las, nesta vida e na futura?</h4>
<p>Essa recompensa não nos é dada por mérito, mas por graça.<sup>1</sup></p>
<ol>
    <li>Lc 17:10</li>
</ol>

<h4>Mas essa doutrina não faz com que os homens se tornem descuidosos e ímpios?</h4>
<p>Não, pois é impossível que aqueles que estão implantados em Cristo, por verdadeira fé, deixem de produzir frutos de gratidão.<sup>1</sup></p>

<ol>
    <li>Mt 7:18; Jo 15:5</li>
</ol>

<hr />
<h2>A Palavra e os sacramentos</h2>


<h3>Domingo 25</h3>
<h4>Visto que somente a fé nos faz participar de Cristo e de todos os seus benefícios, de onde vem esta fé?</h4>
<p>Vem do Espírito Santo<sup>1</sup> que a produz em nossos corações pela pregação do Evangelho,<sup>2</sup> e a fortalece pelo uso dos sacramentos.<sup>3</sup></p>
<ol>
<li>Jo 3:5; 1Co 2:12; 1Co 12:3; Ef 1:17, 18; Ef 2:8; Fp 1:29</li>
<li>At 16:14; Rm 10:17; 1Pe 1:23</li>
<li>Mt 28:19</li>
</ol>
<h4>Que são sacramentos?</h4>
<p>São visíveis e santos sinais e selos. Deus os instituiu para nos fazer compreender melhor e para garantir a promessa do Evangelho, pelo uso deles. Essa promessa é que Deus nos dá, de graça, o perdão dos pecados e a vida eterna, por causa do único sacrifício de Cristo na cruz.<sup>1</sup></p>
<ol>
    <li>Gn 17:11; Lv 6:25; Dt 30:6; Is 6:6,7; Is 54:9; Ez 20:12; Rm 4:11; Hb 9:7,9; Hb 9:24</li>
</ol>

<h4>Então, tanto a Palavra como os sacramentos têm a finalidade de apontar nossa fé para o sacrifício de Jesus Cristo na cruz, como o único fundamento de nossa salvação<sup>1</sup>?</h4>
<p>Sim, pois o Espírito Santo ensina no Evangelho e confirma pelos sacramentos que toda a nossa salvação está baseada no único sacrifício de Cristo na cruz.</p>
<ol>
    <li>Rm 6:3; Gl 3:27</li>
</ol>

<h4>Quantos sacramentos Cristo instituiu na nova aliança?</h4>
<p>Dois: o santo batismo e a santa ceia.</p>
<hr />
<h2>O Santo Batismo</h2>

<h3>Domingo 26</h3>

<h4>Como o batismo ensina e garante a você que o único sacrifício de Cristo na cruz é para seu bem?</h4>
<p>Cristo instituiu essa lavagem com água<sup>1</sup> e acrescentou a promessa de lavar, com seu sangue e Espírito, a impureza da minha alma (isto é, todos os meus pecados)<sup>2</sup> tão certo como por fora fico limpo com a água que tira a sujeira do corpo.</p>
<ol>
    <li>Mt 28:19</li>
    <li>Mt 3:11; Mc 1:4; Mc 16:16; Lc 3:3; Jo 1:33; At 2:38; Rm 6:3,4; 1Pe 3:21</li>    
</ol>


<h4>O que significa ser lavado com o sangue e o Espírito de Cristo?</h4>
<p>Significa receber perdão dos pecados, pela graça de Deus, por causa do sangue de Cristo, que Ele derramou por nós, em seu sacrifício na cruz.<sup>1</sup> Significa também ser renovado pelo Espírito Santo e santificado para ser membro de Cristo. Assim morremos mais e mais para o pecado e levamos uma vida santa e irrepreensível.<sup>2</sup></p>
<ol>
    <li>Ez 36:25; Zc 13:1; Hb 12:24; 1Pe 1:2; Ap 1:5; Ap 7:14</li>
    <li>Ez 36:26,27; Jo 1:33; Jo 3:5; Rm 6:4; 1Co 6:11; 1Co 12:13; Cl 2:11,12</li>
</ol>

<h4>Onde Cristo prometeu lavar-nos com seu sangue e seu Espírito, tão certo como somos lavados com a água do batismo?</h4>
<p>Na instituição do batismo, onde Ele diz: &ldquo;Ide, portanto, fazei discípulos de todas as nações, batizando-os em nome do Pai e do Filho e do Espírito Santo&rdquo; (Mateus 28:19). &ldquo;Quem crer e for batizado será salvo; quem, porem, não crer será condenado&rdquo; (Marcos 16:16). Esta promessa se repete também onde a Escritura chama o batismo de &ldquo;lavagem da regeneração&rdquo; (Tito 3:5) e de &ldquo;purificação dos pecados&rdquo; (Atos 22:16).</p>


<h3>Domingo 27</h3>
<h4>Então, a própria água do batismo é a purificação dos pecados?</h4>
<p>Não,<sup>1</sup> pois somente o sangue de Jesus Cristo e o Espírito Santo nos purificam de todos os pecados.<sup>2</sup></p>
<ol>
    <li>Mt 3:11; Ef 5:26; 1Pe 3:21</li>
    <li>1Co 6:11; 1Jo 1:7</li>    
</ol>

<h4>Por que, então, o Espírito Santo chama o batismo &ldquo;lavagem da regeneração&rdquo; e &ldquo;purificação dos pecados&rdquo;?</h4>
<p>É por motivo muito sério que Deus fala assim. Ele nos quer ensinar que nossos pecados são tirados pelo sangue e Espírito de Cristo assim como a sujeira do corpo é tirada por água.<sup>1</sup> E, ainda mais, Ele nos quer assegurar por este divino sinal e garantia que somos lavados espiritualmente dos nossos pecados tão realmente como nosso corpo fica limpo com água.<sup>2</sup></p>
<ol>
    <li>1Co 6:11; Ap 1:5; Ap 7:14. </li>
    <li>Mt 16:16; Gl 3:27</li>    
</ol>

<h4>As crianças pequenas devem ser batizadas?</h4>
<p>Devem, sim, porque tanto as crianças como os adultos pertencem à aliança de Deus e à sua igreja.<sup>1</sup> Também a elas como aos adultos são prometidos, no sangue de Cristo, a salvação do pecado e o Espírito Santo que produz a fé.<sup>2</sup></p>
<p>Por isso, as crianças, pelo batismo como sinal da aliança, devem ser incorporadas à igreja cristã e distinguidas dos filhos dos incrédulos.<sup>3</sup> Na época do Antigo Testamento se fazia isto pela circuncisão.<sup>4</sup> No Novo Testamento foi instituído o batismo, no lugar da circuncisão.<sup>5</sup></p>
<ol>
    <li>Gn 17:7</li>
    <li>Sl 22:10; Is 44:1–3; Mt 19:14; At 2:39</li>
    <li>At 10:47</li>
    <li>Gn 17:14</li>
    <li>Cl 2:11,12</li>
</ol>

<hr />
<h2>A Santa Ceia</h2>
<h3>Domingo 28</h3>
<h4>Como a santa ceia ensina e garante que você tem parte no único sacrifício de Cristo na cruz e em todos os seus benefícios?</h4>
<p>Da seguinte maneira: Cristo me mandou, assim como a todos os fiéis, comer do pão partido e beber do cálice, em sua memória. E Ele acrescentou esta promessa: Primeiro, que, por mim, seu corpo foi sacrificado na cruz e que, por mim, seu sangue foi derramado, tão certo como vejo com meus olhos que o pão do Senhor é partido para mim e o cálice me é dado. Segundo, que Ele mesmo alimenta e sacia minha alma para a vida eterna com seu corpo crucificado e seu sangue derramado, tão certo como recebo da mão do ministro e tomo com minha boca o pão e o cálice do SenhoEles são sinais seguros do corpo e do sangue de Cristo.<sup>1</sup></p>
<ol>
    <li>Mt 26:26–28; Mc 14:22–24; Lc 22:19,20; 1Co 10:16,17; 1Co 11:23–25</li>
</ol>

<h4>O que significa comer o corpo cruci ficado de Cristo e beber seu sangue derramado?</h4>
<p>Significa aceitar com verdadeira fé todo o sofrimento e morte de Cristo e assim receber o perdão dos pecados e a vida eterna.<sup>1</sup> Significa também ser unido cada vez mais ao santo corpo de Cristo,<sup>2</sup> pelo Espírito Santo que habita tanto nEle como em nós. Assim somos carne de sua carne e osso de seus ossos<sup>3</sup> mesmo que Cristo esteja no céu<sup>4</sup> e nós na terra; e vivemos eternamente e somos governados por um só Espírito, como os membros do nosso corpo o são por uma só alma.<sup>5</sup></p>
<ol>
    <li>Jo 6:35,40,47–54</li>
    <li>Jo 6:55,56</li>
    <li>Jo 14:23; 1Co 6:15,17,19; Ef 3:16,17; Ef 5:29,30; 1Jo 3:24; 1Jo 4:13</li>
    <li>At 1:9,11; At 3:21; 1Co 11:26; Cl 3:1</li>
    <li>Jo 6:57; Jo 15:1–6; Ef 4:15,16</li>
</ol>

<h4>Onde Cristo prometeu alimentar e saciar os fieis com seu corpo e seu sangue, tão certo como eles comem do pão partido e bebem do cálice?</h4>
<p>Nas palavras da instituição da ceia, que são:<sup>1</sup> &ldquo;O Senhor Jesus, na noite em que foi traído, tomou o pão; e, tendo dado graças, o partiu e disse: Isto é o meu corpo que é dado por vós; fazei isto em memória de mim. Por semelhante modo, depois de haver ceado, tomou também o cálice, dizendo: Este cálice é a nova aliança no meu sangue; fazei isto, todas as vezes que o beberdes, em memória de mim. Porque todas as vezes que comerdes este pão e beberdes o cálice, anunciais a morte do Senhor, até que ele venha&rdquo; (1Coríntios 11:23–26). O apóstolo Paulo já se tinha referido a esta promessa, dizendo: &ldquo;Porventura o cálice da bênção que abençoamos, não é a comunhão do sangue de Cristo? O pão que partimos, não é a comunhão do corpo de Cristo? Porque nós, embora muitos, somos unicamente um pão, um só corpo; porque todos participamos do único pão&rdquo; (1Coríntios 10:16,17).</p>
<ol>
    <li>Mt 26:26–28; Mc 14:22–24; Lc 22–19,20</li>
</ol>

<h3>Domingo 29</h3>
<h4>Pão e vinho, então, se transformam no próprio corpo e sangue de Cristo?</h4>
<p>Não.<sup>1</sup> Neste ponto há igualdade entre o batismo e a ceia. A água do batismo não se transforma no sangue de Cristo, nem tira os pecados. Ela é somente um sinal divino e uma garantia disto.<sup>2</sup> Igualmente o pão da santa ceia não se transforma no próprio corpo de Cristo,<sup>3</sup> mesmo que seja chamado &ldquo;corpo de Cristo&rdquo;, conforme a natureza e o uso dos sacramentos.<sup>4</sup></p>
<ol>
    <li>Mt 26:29</li>
    <li>Ef 5:26; Tt 3:5</li>
    <li>1Co 10:16; 1Co 11:26</li>
    <li>Gn 17:10,11; Êx 12: 11,13; Êx 12:26,27; Êx 13:9; Êx 24:8; At 22:16; 1Co 10:1–4; 1Pe 3:21</li>
</ol>


<h4>Por que, então, Cristo chama o pão &ldquo;seu corpo&rdquo; e o cálice &ldquo;seu sangue&rdquo; ou &ldquo;a nova aliança em seu sangue&rdquo;, e por que Paulo fala sobre &ldquo;a comunhão do corpo e do sangue de Cristo&rdquo;?</h4>
<p>É por motivo muito sério que Cristo fala assim. Ele nos quer ensinar que seu corpo crucificado e seu sangue derramado são o verdadeiro alimento e bebida de nossas almas para a vida eterna, assim como pão e vinho mantêm a vida temporária.<sup>1</sup> E, ainda mais, Ele nos quer assegurar por estes visíveis sinais e garantias,primeiro: que participamos de seu corpo e sangue, pela obra do Espírito Santo, tão realmente como recebemos com nossa própria boca estes santos sinais, em memória dEle<sup>2</sup>;e segundo: que todo o seu sofrimento e obediência são nossos, tão certo, como se nós mesmos tivéssemos sofrido e pago por nossos pecados.</p>
<ol>
    <li>Jo 6:51,53–55. </li>
    <li>1Co 10:16</li>
</ol>


<h3>Domingo 30</h3>
<h4>Que diferença há entre a ceia do Senhor e a missa do papa?</h4>
<p>A ceia do Senhor nos testemunha que temos completo perdão de todos os nossos pecados, pelo único sacrifício de Jesus Cristo, que Ele mesmo, uma única vez, realizou na cruz<sup>1</sup>; e também que, pelo Espírito Santo, somos incorporados a Cristo, que agora, com seu verdadeiro corpo, não está na terra mas no céu, à direita do Pai<sup>3</sup> e lá quer ser adorado por nós.<sup>4</sup> A missa, porem, ensina que Cristo deve ser sacrificado todo dia, pelos sacerdotes na missa, em favor dos vivos e dos mortos, e que estes, sem a missa, não tem perdão dos pecados pelo sofrimento de Cristo; e também, que Cristo está corporalmente presente sob a forma de pão e vinho e, por isso, neles deve ser adorado. A missa, então, no fundo, não é outra coisa senão a negação do único sacrifício e sofrimento de Cristo e uma idolatria abominável.<sup>5</sup></p>
<ol>
    <li>Mt 26:28; Lc 22:19,20; Jo 19:30; Hb 7:26,27; Hb 9:12; Hb 9:25–28; Hb 10:10,12,14</li>
    <li>1Co 6:17; 1Co 10:16,17</li>
    <li>Jo 20:17; Cl 3:1; Hb 1:3; Hb 8:1,2</li>
    <li>At 7:55,56; Fp 3:20; Cl 3:1; 1Ts 1:10</li>
    <li>Hb 9:26; Hb 10:12,14</li>
</ol>

<h4>Quem deve vir a santa ceia?</h4>
<p>Aqueles que se aborrecem de si mesmos por causa dos seus pecados, mas confiam que estes lhes foram perdoados por amor de Cristo e que, também, as demais fraquezas são cobertas por seu sofrimento e sua morte; e que desejam, cada vez mais, fortalecer a fé e corrigir-se na vida. Mas os pecadores impenitentes e os hipócritas comem e bebem para sua própria condenação.<sup>1</sup></p>
<ol>
    <li>1Co 10:19–22; 1Co 11:28,29</li>
</ol>

<h4>Podem vir a essa ceia também aqueles que, por sua confissão e vida, se mostram incrédulos e ímpios?</h4>
<p>Não, porque assim é profanada a aliança de Deus e é provocada sua ira sobre toda a congregação.<sup>1</sup> Por isso, a igreja cristã tem a obrigação, conforme o mandamento de Cristo e de seus apóstolos, de excluir tais pessoas, pelas chaves do reino dos céus, até que demonstrem arrependimento.</p>
<ol>
    <li>Sl 50:16; Is 1:11–15; Is 66:3; Jr 7:21–23; 1Co 11:20,34</li>
</ol>


<h3>Domingo 31</h3>
<h4>Que são as chaves do reino dos céus?</h4>
<p>A pregação do santo Evangelho e a disciplina cristã. É por estes dois meios que o reino dos céus se abre para aqueles que crêem e se fecha para aqueles que não crêem.<sup>1</sup></p>
<ol>
    <li>Mt 16:18,19; Mt 18:15–18</li>
</ol>

<h4>Como se abre e se fecha o reino dos céus pela pregação do santo Evangelho?</h4>
<p>Conforme o mandamento de Cristo, se proclama e testifica aos crentes, a todos juntos e a cada um deles, que todos os seus pecados realmente lhes são perdoados por Deus, pelo mérito de Cristo, sempre que aceitam a promessa do Evangelho com verdadeira fé. Mas a todos os incrédulos e hipócritas se proclama e testifica que a ira de Deus e a condenação permanecem sobre eles, enquanto não se converterem.<sup>1</sup> Segundo este testemunho do Evangelho Deus julgará todos, nesta vida e na futura.</p>
<ol>
    <li>Mt 16:19; Jo 20:21–23</li>
</ol>

<h4>Como se fecha e se abre o reino dos céus pela disciplina cristã?</h4>
<p>Conforme o mandamento de Cristo, aqueles que, com o nome de cristãos, se comportam na doutrina ou na vida como não-cristãos, são fraternalmente advertidos, repetidas vezes. Se não querem abandonar seus erros ou maldades, são denunciados à igreja e aos que, pela igreja, foram ordenados para este fim. Se não dão atenção nem a admoestação destes, não são mais admitidos aos sacramentos e, assim, excluídos da congregação de Cristo, e, pelo próprio Deus, do reino de Cristo. Eles voltam a ser recebidos como membros de Cristo e da sua igreja, quando realmente prometem e demonstram verdadeiro arrependimento.<sup>1</sup></p>
<ol>
    <li>Mt 18:15–18; 1Co 5:3–5,11; 2Co 2:6–8; 2Ts 3:14,15; 1Tm 5:20; 2 Jo :10,11</li>
</ol>

<hr />
<h2>Parte 3: Nossa gratidão</h2>

<h3>Domingo 32</h3>
<h4>Visto que fomos libertados de nossa miséria, por Cristo, sem mérito algum de nossa parte, somente pela graça, por que ainda devemos fazer boas obras?</h4>
<p>Primeiro: porque Cristo não somente nos comprou e libertou com seu sangue, mas também nos renova, à sua imagem, por seu Espírito Santo, para que mostremos, com toda a nossa vida, que somos gratos a Deus por seus benefícios,<sup>1</sup> e para que Ele seja louvado por nós.<sup>2</sup> Segundo: para que, pelos frutos da fé, tenhamos a certeza de que nossa fé é verdadeira<sup>3</sup> e para que ganhemos nosso próximo para Cristo, pela vida cristã que levamos.<sup>4</sup></p>

<ol>
    <li>Rm 6:13; Rm 12:1,2; 1Co 6:20; 1Pe 2:5,9</li>
    <li>Mt 5:16; 1Pe 2:12</li>
    <li>Mt 7:17,18; Gl 5:6,22; 2Pe 1:10</li>
    <li>Mt 5:16; Rm 14:18,19; 1Pe 3:1,2</li>
</ol>

<h4>Não podem ser salvos, então, aqueles que continuam vivendo sem Deus e sem gratidão e não se convertem a Ele?</h4>
<p>De maneira alguma, porque a Escritura diz que nenhum impuro, idólatra, adúltero, ladrão, avarento, bêbado, maldizente, assaltante ou semelhante herdará o reino de Deus.<sup>1</sup></p>
<ol>
    <li>1Co 6:9,10; Ef 5:5,6; 1Jo 3:14,15</li>
</ol>

<h3>Domingo 33</h3>
<h4>Quantas partes há na verdadeira conversão do homem?</h4>
<p>Duas: a morte do velho homem e o nascimento do novo homem.<sup>1</sup></p>

<ol>
    <li>Rm 6:4–6; 1Co 5:7; 2Co 7:10; Ef 4:22–24; Cl 3:5–10</li>
</ol>

<h4>O que é a morte do velho homem?</h4>
<p>É a profunda tristeza por causa dos pecados e a vontade de odiá-los e evitá-los, cada vez mais.<sup>1</sup></p>
<ol>
    <li>Jl 2:13; Rm 8:13</li>
</ol>

<h4>O que é o nascimento do novo homem?</h4>

<p>É a alegria sincera em Deus, por Cristo,<sup>1</sup> e o forte desejo de viver conforme a vontade de Deus em todas as boas obras.<sup>2</sup></p>

<ol>
    <li>Is 57:15; Rm 5:1,2; Rm 14:17</li>
    <li>Rm 6:10,11; Gl 2:19,20</li>
</ol>

<h4>Que são boas obras?</h4>
<p>São somente aquelas que são feitas com verdadeira fé<sup>1</sup> conforme a lei de Deus<sup>2</sup> e para sua glória<sup>3</sup>; não são aquelas que se baseiam em nossa própria opinião ou em tradições humanas.<sup>4</sup></p>
<ol>
    <li>Rm 14:23</li>
    <li>Lv 18:4; 1Sm 15:22; Ef 2:10</li>
    <li>1Co 10:31</li>
    <li>Is 29:13,14; Ez 20:18,19; Mt 15:7–9</li>
</ol>
<hr />
<h2>Os Dez Mandamentos</h2>

<h3>Domingo 34</h3>

<h4>Que diz a lei do <span class="smallcaps">Senhor</span>?</h4>
<p>Deus falou todas estas palavras (Êxodo 20:1–17; Deuteronômio 5:6–2<sup>1</sup>: &ldquo;Eu sou o <span class="smallcaps">Senhor</span> teu Deus, que te tirei da terra do Egito, da casa da servidão.&rdquo;</p>
<p><strong>Primeiro mandamento: </strong>&ldquo;Não terás outros deuses diante de mim.&rdquo;</p>

<p><strong>Segundo mandamento:</strong> &ldquo;Não farás para ti imagem de escultura, nem semelhança alguma do que há em cima nos céus, nem em baixo na terra, nem nas águas debaixo da terra. Não as adorarás, nem lhes darás culto; porque eu sou o <span class="smallcaps">Senhor</span> teu Deus, Deus zeloso, que visito a iniqüidade dos pais nos filhos até a terceira e quarta geração daqueles que me aborrecem, e faço misericórdia até mil gerações daqueles que me amam e guardam os meus mandamentos.&rdquo;</p>

<p><strong>Terceiro mandamento:</strong> &ldquo;Não tomarás o nome do <span class="smallcaps">Senhor</span> teu Deus em vão, porque o <span class="smallcaps">Senhor</span> não terá por inocente o que tomar seu nome em vão.&rdquo;</p>

<p><strong>Quarto mandamento:</strong> &ldquo;Lembra-te do dia de descanso, para o santificaSeis dias trabalharás, e farás toda a tua obra. Mas o sétimo dia é o sábado do <span class="smallcaps">Senhor</span> teu Deus; não farás nenhum trabalho, nem tu, nem teu filho, nem tua filha, nem o teu servo, nem a tua serva, nem o teu animal, nem o forasteiro das tuas portas para dentro; porque em seis dias fez o <span class="smallcaps">Senhor</span> os céus e a terra, o mar e tudo o que neles há, e ao sétimo dia descansou: por isso o <span class="smallcaps">Senhor</span> abençoou o dia de sábado, e o santificou.&rdquo;</p>

<p><strong>Quinto mandamento:</strong> &ldquo;Honra a teu pai e a tua mãe, para que se prolonguem os teus dias na terra que o <span class="smallcaps">Senhor</span> teu Deus te dá.&rdquo;</p>

<p><strong>Sexto mandamento:</strong> &ldquo;Não matarás.&rdquo;</p>

<p><strong>Sétimo mandamento:</strong> &ldquo;Não adulterarás.&rdquo;</p>

<p><strong>Oitavo mandamento:</strong> &ldquo;Não furtarás.&rdquo;</p>

<p><strong>Nono mandamento:</strong> &ldquo;Não dirás falso testemunho contra o teu próximo.&rdquo;</p>

<p><strong>Décimo mandamento:</strong> &ldquo;Não cobiçarás a casa do teu próximo. Não cobiçarás a mulher do teu próximo, nem o seu servo, nem a sua serva, nem o seu boi, nem o seu jumento, nem coisa alguma que pertença ao teu próximo.&rdquo;</p>

<h4>Como se dividem estes Dez Mandamentos?</h4>
<p>Em duas partes.<sup>1</sup> A primeira nos ensina, em quatro mandamentos, como devemos viver diante de Deus; a segunda nos ensina, em seis mandamentos, as nossas obrigações para com nosso próximo.<sup>2</sup></p>
<ol>
    <li>Êx 31:18; Dt 4:13; Dt 10:3,4</li>
    <li>Mt 22:37–40</li>
</ol>

<h4>O que Deus ordena no primeiro mandamento?</h4>
<p>Primeiro: para não perder minha salvação, devo evitar e fugir de toda idolatria,<sup>1</sup> feitiçaria, adivinhação e superstição.<sup>2</sup> Também não posso invocar os santos ou outras criaturas.<sup>3</sup> Segundo: devo reconhecer devidamente o único e verdadeiro Deus,<sup>4</sup> confiar somente nEle,<sup>5</sup> me submeter somente a Ele<sup>6</sup> com toda humildade<sup>7</sup> e paciência. Devo amar,<sup>8</sup> temer<sup>9</sup> e honrar<sup>10</sup> a Deus de todo o coração, e esperar todo o bem somente dEle.<sup>11</sup> Em resumo, devo renunciar a todas as criaturas e não fazer a menor coisa contra a vontade de Deus.<sup>12</sup></p>
<ol>
    <li>1Co 6:10; 1Co 10:7,14; 1Jo 5:21</li>
    <li>Lv 19:31; Dt 18:9–12</li>
    <li>Mt 4:10; Ap 19:10; Ap 22:8,9</li>
    <li>Jo 17:3</li>
    <li>Jr 17:5,7</li>
    <li>Rm 5:3–5; 1Co 10:10; Fp 2:14; Cl 1:11; Hb 10:36</li>
    <li>1Pe 5:5</li>
    <li>Dt 6:5; Mt 22:37,38</li>
    <li>Dt 6:2; Sl 111:10; Pv 1:7; Pv 9:10; Mt 10:28</li>
    <li>Dt 10:20; Mt 4:10</li>
    <li>Sl 104:27–30; Is 45:7; Tg 1:17</li>
    <li>Mt 5:29,30; Mt 10:37–39; At 5:29</li>
</ol>

<h4>Que é idolatria?</h4>
<p>Idolatria é inventar ou ter alguma coisa em que se deposite confiança, em lugar ou ao lado do único e verdadeiro Deus, que se revelou em sua Palavra.<sup>1</sup></p>
<ol>
    <li>1Cr 16:26; Is 44:16,17; Jo 5:23; Gl 4:8; Ef 2:12; Ef 5:5; Fp 3:19; 1Jo 2:23; 2 Jo :9</li>
</ol>


<h3>Domingo 35</h3>
<h4>O que Deus exige no segundo mandamento?</h4>
<p>Não podemos, de maneira alguma, representar Deus por imagem ou figura.<sup>1</sup> Devemos adorá-Lo somente da maneira que Ele ordenou em sua palavra.<sup>2</sup></p>
<ol>
    <li>Dt 4:15,16; Is 40:18,19,25; At 17:29; Rm 1:23–25</li>
    <li>Dt 12:30–32; 1Sm 15:23; Mt 15:9</li>
</ol>

<h4>Não se pode fazer imagem alguma?</h4>
<p>Não se pode nem deve fazer nenhuma imagem de Deus. As criaturas podem ser representadas, mas Deus nos proíbe fazer ou ter imagens delas para adorá-las ou para servir a Deus por meio delas.<sup>1</sup></p>
<ol>
    <li>Êx 34:13,14,17; Dt 12:3,4; Dt 16:22; 2Rs 18:4 Is 40:25</li>
</ol>

<h4>Mas não podem ser toleradas as imagens nas igrejas como &ldquo;livros para ignorantes&rdquo;?</h4>
<p>Não, porque não devemos ser mais sábios do que Deus. Ele não quer ensinar a seu povo por meio de ídolos mudos,<sup>1</sup> mas pela pregação viva de sua Palavra.<sup>2</sup></p>
<ol>
    <li>Jr 10:5,8; Hc 2:18,19</li>
    <li>Rm 10:14–17; 2Tm 3:16,17; 2Pe 1:19</li>
</ol>

 

<h3>Domingo 36</h3>
<h4>O que Deus exige no terceiro mandamento?</h4>
<p>Não devemos blasfemar ou profanar o santo nome de Deus por maldições<sup>1</sup> ou juramentos falsos<sup>2</sup> nem por juramentos desnecessários.<sup>3</sup> Também não devemos tomar parte em pecados tão horríveis, ficando calados quando os ouvimos.<sup>4</sup> Em resumo, devemos usar o santo nome de Deus somente com temor e reverencia<sup>5</sup> a fim de que Ele, por nós, seja devidamente confessado,<sup>6</sup> invocado<sup>7</sup> e glorificado por todas as nossas palavras e obras.<sup>8</sup></p>
<ol>
    <li>Lv 24:15,16</li>
    <li>Lv 19:12</li>
    <li>Mt 5:37; Tg 5:12</li>
    <li>Lv 5:1; Pv 29:24</li>
    <li>Is 45:23; Jr 4:2</li>
    <li>Mt 10:32; Rm 10:9,10</li>
    <li>Sl 50:15; 1Tm 2:8</li>
    <li>Rm 2:24; Cl 3:17; 1Tm 6:1</li>
</ol>

<h4>Será que blasfemar o nome de Deus por juramentos e maldições é um pecado tão grande, que Deus se ira também contra aqueles que não se esforçam para impedir e proibir tal coisa?</h4>
<p>Claro que sim, pois não há pecado maior ou que mais provoque a ira de Deus do que blasfemar seu nome. Por isso, Ele mandava castigar este pecado com a pena da morte.<sup>1</sup></p>
<ol>
    <li>Lv 24:16; Ef 5:11</li>
</ol>

<h3>Domingo 37</h3>
<h4>Mas não podemos nós, de modo piedoso, fazer juramento em nome de Deus?</h4>
<p>Podemos sim, quando as autoridades o exigirem ou quando for preciso, para manter e promover a fidelidade e a verdade, para a glória de Deus e o bem-estar do próximo. Por tal juramento está baseado na Palavra de Deus<sup>1</sup> e era praticado, com razão, pelos santos do Antigo e Novo Testamento.<sup>2</sup></p>
<ol>
    <li>Dt 6:13; Dt 10:20; Hb 6:16</li>
    <li>Gn 21:24; Gn 31:53; 1Sm 24:22,23; 2Sm 3:35; 1Rs 1:29,30; Rm 1:9; Rm 9:1; 2Co 1:23</li>
</ol>

<h4>Podemos jurar também pelos santos ou por outras criaturas?</h4>
<p>Não, porque o juramento legítimo é uma invocação a Deus, para que Ele, o único que conhece os corações, testemunhe a verdade e nos castigue, se jurarmos falsamente.<sup>1</sup> Tal honra não pertence a criatura alguma.<sup>2</sup></p>

<ol>
    <li>Rm 9:1; 2Co 1:23</li>
    <li>Mt 5:34–36; Tg 5:12</li>
</ol>


<h3>Domingo 38</h3>
<h4>O que Deus ordena no quarto mandamento?</h4>
<p>Primeiro: o ministério do Evangelho e as escolas cristãs devem ser mantidos,<sup>1</sup> e eu devo reunir-me fielmente com o povo de Deus, especialmente no dia de descanso,<sup>2</sup> para conhecer a palavra de Deus,<sup>3</sup> para participar dos sacramentos,<sup>4</sup> para invocar publicamente ao Senhor Deus<sup>5</sup> e para praticar a caridade cristã para com os necessitados.<sup>6</sup> Segundo: eu devo, todos os dias da minha vida, desistir das más obras, deixando o Senhor operar em mim, por seu Espírito. Assim começo nesta vida o descanso eterno.<sup>7</sup></p>


<ol>
    <li>1Co 9:13,14; 1Tm 3:15; 2Tm 2:2; 2Tm 3:14,15; Tt 1:5</li>
    <li>Lv 23:3; Sl 40:9,10; Sl 122:1; At 2:42,46</li>
    <li>1Co 14:1,3; 1Tm 4:13; Ap 1:3</li>
    <li>At 20:7; 1Co 11:33</li>
    <li>1Co 14:16; 1Tm 2:1–4</li>
    <li>Dt 15:11; 1Co 16:1,2; 1Tm 5:16</li>
    <li>Hb 4:9,10</li>
</ol>


<h3>Domingo 39</h3>

<h4>O que Deus exige no quinto mandamento?</h4>
<p>Devo prestar toda honra, amor e fidelidade a meu pai e a minha mãe e a todos os meus superiores; devo submeter-me à sua boa instrução e disciplina com a devida obediência,<sup>1</sup> e também ter paciência com seus defeitos<sup>2</sup>; porque Deus nos quer governar pelas mãos deles.<sup>3</sup></p>
<ol>
    <li>Êx 21:17; Pv 1:8; Pv 4:1; Pv 15:20; Pv 20:20; Rm 13:1; Ef 5:22; Ef 6:1,2,5; Cl 3:18,20,22</li>
    <li>Pv 23:22; 1Pe 2:18</li>
    <li>Mt 22:21; Rm 13:2,4; Ef 6:4; Cl 3:20</li>
</ol>


<h3>Domingo 40</h3>
<h4>O que Deus exige no sexto mandamento?</h4>
<p>Eu não devo desonrar, odiar, ofender ou matar meu proximo,<sup>1</sup> por mim mesmo ou através de outros. Isto não posso fazer, nem por pensamentos, palavras, ou gestos e muito menos por atos. Mas devo abandonar todo desejo de vingança,<sup>2</sup> não fazer mal a mim mesmo ou, de propósito, colocar-me em perigo.<sup>3</sup></p>
<p>Por isso as autoridades dispõem das armas para impedir homicídios.<sup>4</sup></p>
<ol>
    <li>Gn 9:6; Mt 5:21,22; Mt 26:52</li>
    <li>Mt 5:25; Mt 18:35; Rm 12:19; Ef 4:26</li>
    <li>Mt 4:7; Cl 2:23</li>
    <li>Gn 9:6; Êx 21:14; Rm 13:4</li>
</ol>

<h4>Este mandamento trata somente de matar?</h4>
<p>Não, proibindo o homicídio, Deus nos ensina que Ele detesta a raiz do homicídio, a saber: a inveja,<sup>1</sup> o ódio,<sup>2</sup> a ira<sup>3</sup> e o desejo de vingança. Ele considera tudo isto homicídio.<sup>4</sup></p>

<ol>
    <li>Sl 37:8; Pv 14:30; Rm 1:29</li>
    <li>1Jo 2:9–11</li>
    <li>Tg 1:20; Gl 5:19–21</li>
    <li>1Jo 3:15</li>
</ol>

<h4>Mas é suficiente não matar nosso próximo?</h4>
<p>Não, porque Deus, condenando a inveja, o ódio e a ira, manda que amemos nosso próximo como a nós mesmos<sup>1</sup> e mostremos paciência, paz, mansidão, misericórdia e gentileza para com ele.<sup>2</sup> Devemos evitar seu prejuízo, tanto quanto possível,<sup>3</sup> e fazer bem até aos nossos inimigos.<sup>4</sup></p>
<ol>
    <li>Mt 7:12; Mt 22:39; Rm 12:10</li>
    <li>Mt 5:5,7; Lc 6:36; Rm 12:18; Gl 6;1–2; Ef 4:1–3; Cl 3:12; 1Pe 3:8</li>
    <li>Êx 23:5</li>
    <li>Mt 5:44,45; Rm 12:20–21</li>
</ol>

 

<h3>Domingo 41</h3>
<h4>O que o sétimo mandamento nos ensina?</h4>
<p>Toda impureza sexual é amaldiçoada por Deus.<sup>1</sup> Por isso, devemos detestá-la profundamente e viver de maneira pura e disciplinada,<sup>2</sup> sejamos casados ou solteiros.<sup>3</sup></p>
<ol>
    <li>Lv 18:27–29</li>
    <li>1Ts 4:3–5</li>
    <li>Ml 2:16; Mt 19:9; 1Co 7:10,11; Hb 13:4</li>
</ol>

<h4>Mas Deus, neste mandamento, proíbe somente adultério e outros pecados vergonhosos?</h4>
<p>Não, pois como nosso corpo e nossa alma são o templo do Espírito Santo, Deus quer que os conservemos puros e santos.<sup>1</sup> Por isso, Ele proíbe todos os atos, gestos, palavras,<sup>2</sup> pensamentos e desejos<sup>3</sup> impuros e tudo o que possa atrair o homem para tais pecados.<sup>4</sup></p>
<ol>
    <li>1Co 6:18–20</li>
    <li>Dt 22:20–29; Ef 5:3,4</li>
    <li>Mt 5:27,28</li>
    <li>1Co 15:33; Ef 5:18</li>
</ol>


<h3>Domingo 42</h3>
<h4>O que Deus proíbe no oitavo mandamento?</h4>
<p>Deus não somente proíbe o furto<sup>1</sup> e o roubo<sup>2</sup> que as autoridades castigam, mas também classifica como roubo todos os maus propósitos e as práticas maliciosas, através dos quais tentamos nos apropriar dos bens do próximo,<sup>3</sup> seja por força, seja por aparência de direito, a saber: falsificação de peso, de medida, de mercadoria e de moeda,<sup>4</sup> seja por juros exorbitantes ou por qualquer outro meio, proibido por Deus.<sup>5</sup> Também proíbe toda avareza<sup>6</sup> bem como todo abuso e desperdício de suas dádivas.<sup>7</sup></p>
<ol>
    <li>1Co 6:10</li>
    <li>Lv 19:13</li>
    <li>Lc 3:14; 1Co 5:10</li>
    <li>Dt 25:13–15; Pv 11:1; Pv 16:11; Ez 45:9–12</li>
    <li>Sl 15:5; Lc 6:35</li>
    <li>1Co 6:10</li>
    <li>Pv 21:20; Pv 23:20,21</li>
</ol>

<h4>Mas o que Deus ordena neste mandamento?</h4>
<p>Devo promover tanto quanto possível, o bem do meu próximo e tratá-lo como quero que outros me tratem.<sup>1</sup> Além disto, devo fazer fielmente meu trabalho para que possa ajudar ao necessitado.<sup>2</sup></p>
<ol>
    <li>Mt 7:12. </li>
    <li>Ef 4:28</li>
</ol>


<h3>Domingo 43</h3>
<h4>O que Deus exige no nono mandamento?</h4>
<p>Jamais posso dar falso testemunho contra meu próximo,<sup>1</sup> nem torcer suas palavras<sup>2</sup> ou ser mexeriqueiro ou caluniador.<sup>3</sup> Também não posso ajudar a condenar alguém levianamente, sem o ter ouvido.<sup>4</sup> Mas devo evitar toda mentira e engano, obras próprias do diabo,<sup>5</sup> para Deus não ficar aborrecido comigo.<sup>6</sup> Em julgamentos e em qualquer outra ocasião, devo amar a verdade, falar a verdade e confessá-la francamente.<sup>7</sup> Também devo defender e promover, tanto quanto puder, a honra e a boa reputação de meu próximo.<sup>8</sup></p>

<ol>
    <li>Pv 19:5,9; Pv 21:28</li>
    <li>Sl 50:19,20</li>
    <li>Sl 15:3; Rm 1:30</li>
    <li>Mt 7:1,2; Lc 6:37</li>
    <li>Jo 8:44</li>
    <li>Pv 12:22</li>
    <li>1Co 13:6; Ef 4:25</li>
    <li>1Pe 4:8</li>
</ol>

<h3>Domingo 44</h3>
<h4>O que Deus exige no décimo mandamento?</h4>
<p>Jamais pode surgir em nosso coração o menor desejo ou pensamento contra qualquer mandamento de Deus. Pelo contrário, devemos sempre, de todo o coração odiar todos os pecados e amar toda justiça.<sup>1</sup></p>
<ol>
    <li>Rm 7:7</li>
</ol>


<h4>Mas aqueles que se converteram a Deus, podem guardar perfeitamente estes mandamentos?</h4>
<p>Não, não podem, porque nesta vida até os mais santos deles apenas começam a guardar os mandamentos.<sup>1</sup> Entretanto, começam, com sério propósito, a viver não somente conforme alguns, mas conforme todos os mandamentos de Deus.<sup>2</sup></p>
<ol>
    <li>Ec 7:20; Rm 7:14,15; 1Co 13:9; 1Jo 1:8,10</li>
    <li>Sl 1:2; Rm 7:22; 1Jo 2:3</li>
</ol>

<h4>Para que, então, manda Deus pregar os Dez Mandamentos tão rigorosamente, já que ninguém pode guardá-los nesta vida?</h4>
<p>Primeiro: para que, durante toda a vida, conheçamos cada vez melhor nossa natureza pecaminosa<sup>1</sup> e, por isso, ainda mais desejemos buscar, em Cristo, o perdão dos pecados e a justiça.<sup>2</sup> Segundo: para que sempre sejamos zelosos e oremos a Deus pela graça do Espírito Santo, a fim de que sejamos cada vez mais renovados segundo a imagem de Deus até que, depois desta vida, alcancemos o objetivo, a saber: a perfeição.<sup>3</sup></p>

<ol>
    <li>Sl 32:5; Rm 3:20; 1Jo 1:9</li>
    <li>Mt 5:6; Rm 7:24,25</li>
    <li>1Co 9:24; Fp 3:12–14</li>
</ol>

<hr />
<h2>A oração</h2>


<h3>Domingo 45</h3>
<h4>Por que a oração e necessária aos cristãos?</h4>
<p>Porque a oração é a parte principal da gratidão, que Deus requer de nós.<sup>1</sup> Além disto, Deus quer conceder sua graça e seu Espírito Santo somente aos que continuamente Lhe pedem e agradecem, de todo o coração.<sup>2</sup></p>
<ol>
    <li>Sl 50:14,15. </li>
    <li>Mt 7:7,8; Lc 11:9,10; 1Ts 5:17,18</li>
</ol>

<h4>Como devemos orar, para que a oração seja agradável a Deus e Ele nos ouça?</h4>
<p>Primeiro: devemos invocar, de todo o coração,<sup>1</sup> o único e verdadeiro Deus, que se revelou a nós em sua palavra,<sup>2</sup> e orar por tudo o que Ele nos ordenou pedir.<sup>3</sup> Segundo: devemos muito bem conhecer nossa necessidade e miséria,<sup>4</sup> a fim de nos humilharmos perante sua majestade.<sup>5</sup> Terceiro: devemos ter a plena certeza<sup>6</sup> de que Deus, apesar de nossa indignidade, quer atender à nossa oração,<sup>7</sup> por causa de Cristo, como Ele prometeu em sua Palavra.<sup>8</sup></p>
<ol>
    <li>Sl 145:18–20; Tg 4:3,8</li>
    <li>Jo 4:22–24; Ap 19:10</li>
    <li>Rm 8:26; Tg 1:5; 1Jo 5:14</li>
    <li>2Cr 20:12; Sl 143:2</li>
    <li>Sl 2:11; Sl 51:17; Is 66:2</li>
    <li>Rm 8:15,16; Rm 10:14; Tg 1:6–8</li>
    <li>Dn 9:17–19; Jo 14:13,14; Jo 15:16; Jo 16:23</li>
    <li>Sl 27:8; Sl 143:1; Mt 7:8</li>
</ol>

<h4>O que Deus ordenou pedir a Ele?</h4>
<p>Tudo o que é necessário ao nosso corpo e a nossa alma,<sup>1</sup> como Cristo, o Senhor, o resumiu na oração que Ele mesmo nos ensinou.</p>
<ol>
    <li>Mt 6:33; Tg 1:17</li>
</ol>

<h4>Que diz esta oração?</h4>
<p>&ldquo;Pai nosso, que estás nos céus, santificado seja o teu nome;venha o teu reino,faça-se a tua vontade, assim na terra como no céu;o pão nosso de cada dia dá-nos hoje;e perdoa-nos as nossas dívidas, assim como nós temos perdoado aos nossos devedores;e não nos deixes cair em tentação, mas livra nos do mal; pois teu é o reino, o poder e a glória para sempre. Amém&rdquo; (Mt 6:9–13; Lc 11:2–4).</p>

<h3>Domingo 46</h3>
<h4>Por que Cristo nos ordenou dizer a Deus &ldquo;Pai nosso&rdquo;?</h4>
<p>Porque Cristo quer despertar em nós, logo no início como base da oração, respeito e confiança, como uma criança para com Deus. Pois Deus se tornou nosso Pai, por Cristo. E muito menos do que nossos pais nos recusam bens materiais, Ele nos recusará o que Lhe pedirmos com verdadeira fé.<sup>1</sup></p>
<ol>
    <li>Mt 7:9–11; Lc 11:11–13</li>
</ol>


<h4>Por que se acrescenta: &ldquo;que estás nos céus&rdquo;?</h4>
<p>Porque assim Cristo nos ensina a não ter idéia terrena da majestade celestial de Deus<sup>1</sup> e a esperar, da onipotência dEle, tudo o que é necessário ao nosso corpo e a nossa alma.<sup>2</sup></p>
<ol>
    <li>Jr 23:23,24; At 17:24–27. </li>
    <li>Rm 10:12</li>
</ol>


<h3>Domingo 47</h3>
<h4>Qual e a primeira petição?</h4>
<p>&ldquo;Santificado seja o teu nome.&rdquo; Quer dizer: Faze primeiro, com que Te conheçamos em verdade<sup>1</sup> e Te santifiquemos, honremos e glorifiquemos em todas as tuas obras,<sup>2</sup> em que brilham tua onipotência, sabedoria, bondade, justiça, misericórdia e verdade. Faze, também, com que dirijamos toda a nossa vida -nossos pensamentos, palavras e obras- de tal maneira que teu nome não seja blasfemado por nossa causa, mas honrado e glorificado.<sup>3</sup></p>

<ol>
    <li>Sl 119:105; Jr 9:24; Jr 31:33–34; Mt 16:17; Jo 17:3; Tg 1:5</li>
    <li>Êx 34:6–7; Sl 119:137–138; Sl 145:8–9; Jr 31:3; Jr 32:18–19; Mt 19:17; Lc 1:46–55; Lc 1:68–69; Rm 3:3–4; Rm 11:22–23; Rm 11:33</li>
    <li>Sl 71:8; Sl 115:1; Mt 5:16</li>
</ol>


<h3>Domingo 48</h3>
<h4>Qual é a segunda petição?</h4>
<p>&ldquo;Venha o teu reino.&rdquo; Quer dizer: Governa-nos por tua palavra e por teu Espírito, de tal maneira que, cada vez mais, nos submetamos a Ti<sup>1</sup>;conserve e aumenta tua igreja<sup>2</sup>;destrói as obras do diabo, e todo poder que se levanta contra Ti, e todos os maus planos que são inventados contra tua santa Palavra<sup>3</sup>;até que venha a plenitude de teu reino,<sup>4</sup> em que Tu serás tudo em todos.<sup>5</sup></p>
<ol>
    <li>Sl 119:5; Sl 143:10; Mt 6:33</li>
    <li>Sl 51:18; Sl 122:6,7</li>
    <li>Rm 16:20; 1Jo 3:8</li>
    <li>Rm 8:22,23; Ap 22:17,20</li>
    <li>1Co 15:28</li>
</ol>


<h3>Domingo 49</h3>
<h4>Qual é a terceira petição ?</h4>
<p>&ldquo;Faça-se a tua vontade, assim na terra como no céu.&rdquo; Quer dizer: Faze com que nós e todos os homens renunciemos à nossa própria vontade<sup>1</sup> e obedeçamos, sem protestos, à tua vontade,<sup>2</sup> a única que é boa, para que cada um, assim, cumpra sua tarefa e vocação,<sup>3</sup> tão pronta e fielmente como os anjos no céu.<sup>4</sup></p>
<ol>
    <li>Mt 16:24; Tt 2:11,12</li>
    <li>Lc 22:42; Rm 12:2; Ef 5:10</li>
    <li>1Co 7:22–24</li>
    <li>Sl 103:20,21</li>
</ol>


<h3>Domingo 50</h3>
<h4>Qual é a quarta petição?</h4>
<p>&ldquo;O pão nosso de cada dia dá-nos hoje.&rdquo; Quer dizer: Cuida de nós com tudo o que for necessário ao nosso corpo,<sup>1</sup> para que reconheçamos que Tu és a única fonte de todo o bem<sup>2</sup> e que, sem tua bênção, nem nosso cuidado e trabalho, nem teus dons nos são úteis.<sup>3</sup> Faze também com que, por isso, não mais depositemos nossa confiança em qualquer criatura, mas somente em Ti.<sup>4</sup></p>
<ol>
    <li>Sl 104:27,28; Sl 145:15,16; Mt 6:25,26</li>
    <li>At 14:17; At 17:27,28; Tg 1:17</li>
    <li>Dt 8:3; Sl 37:3–7,16,17; Sl 127:1,2; 1Co 15:58</li>
    <li>Sl 55:22; Sl 62:10; Sl 146:3; Jr 17:5,7</li>
</ol>


<h3>Domingo 51</h3>
<h4>Qual é a quinta petição?</h4>
<p>&ldquo;E perdoa-nos as nossas dividas, assim como nós temos perdoado aos nossos devedores.&rdquo; Quer dizer: Por causa do sangue de Cristo, não cobres de nós, miseráveis pecadores que somos, nossas transgressões nem o mal que ainda há em nós,<sup>1</sup> assim como tua graça em nós fez com que tenhamos o firme propósito de perdoar nosso próximo, de todo o coração.<sup>2</sup></p>
<ol>
    <li>Sl 51:1; Sl 143:2; Rm 8:1; 1Jo 2:1. </li>
    <li>Mt 6:14,15</li>
</ol>


<h3>Domingo 52</h3>
<h4>Qual é a sexta petição?</h4>
<p>&ldquo;E não nos deixes cair em tentação, mas livra-nos do mal.&rdquo; Quer dizer: Somos tão fracos que, por nós mesmos, não podemos subsistir por um só momento<sup>1</sup>; além disto, nossos inimigos declarados: o diabo,<sup>2</sup> o mundo<sup>3</sup> e nossa própria carne<sup>4</sup> nos tentam continuamente. Por isso, Te pedimos: sustenta-nos e fortalece-nos, pelo poder de teu Espírito Santo, a fim de que neste combate espiritual não sejamos derrotados,<sup>5</sup> mas possamos fortemente resistir, até que finalmente alcancemos a vitória total.<sup>6</sup></p>
<ol>
    <li>Sl 103:14–16; Jo 15:5</li>
    <li>Ef 6:12; 1Pe 5:8</li>
    <li>Jo 15:19</li>
    <li>Rm 7:23; Gl 5:17</li>
    <li>Mt 26:41; Mc 13:33; 1Co 10:12,13</li>
    <li>1Ts 3:13; 1Ts 5:23</li>
</ol>

<h4>Como você termina sua oração?</h4>
<p>&ldquo;Pois teu é o reino, o poder e a glória, para sempre.&rdquo; Quer dizer: Tudo isso Te pedimos, porque Tu queres e podes dar-nos todo o bem, pois és nosso Rei e tudo está em teu poder.<sup>1</sup> Pedimos-Te isso também, para que não o nosso, mas teu santo nome seja eternamente glorificado.<sup>2</sup></p>
<ol>
    <li>1Cr 29:10–12; Rm 10:11–13; 2Pe 2:9. </li>
    <li>Sl 115:1; Jr 33:8,9; Jo 14:13</li>
</ol>

<h4>O que significa a palavra: &ldquo;amém&rdquo;?</h4>
<p>Amém quer dizer: é verdadeiro e certo. Pois Deus atende à minha oração com muito mais certeza do que o desejo que eu sinto, no coração, de ser ouvido por Ele.<sup>1</sup></p>
<ol>
    <li>2Co 1:20; 2Tm 2:13</li>
</ol>
            </Col>
        </Row>
      {/* Rodapé */}
      <Footer />
    </Container>
  </div>
)
